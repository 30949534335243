<template lang="pug">
	.component
		ContainerForData.mt-24(width="100%" :isLoading="isLoading")
			template(#header-left)
				.flex.items-center
					p Average Rating By {{ group }}
					Tooltip.ml-8(
						v-if="false"
						trigger="hover"
					)
						template(slot="reference")
							HelpCircle.icon
						span 'Tooltip'
			template(#data)
				BarChart(
					ref="bar"
					:chartData="stackedBarChartData"
					:options="stackedBarChartOptions"
					:style="{'height': `${180 + (40 * (lengthOfResult - 1)) }px`}"
				)
</template>

<script>
import Tooltip from '@/components/Elements/Tooltip.vue'
import Tabs from "@/components/Nestle/Tabs"
import ContainerForData from "@/components/Nestle/ContainerForData"
import BarChart from "@/components/Chart/BarChart"
import HelpCircle from 'vue-material-design-icons/HelpCircle.vue'

export default {
	name: "ProductCompliance",
	components: {
		Tabs,
		Tooltip,
		ContainerForData,
		BarChart,
		HelpCircle,
	},
	props: {
		response: {
			type: Object,
			default: () => {},
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		group: {
			type: String,
			default: 'Store',
		},
		activeGroup: {
			type: String,
			default: 'stores',
		},
		activeTab: {
			type: String,
			default: 'Sku',
		},
	},
	computed:{
		lengthOfResult() {
			if (!this.response[this.activeGroup]) return
			return this.response[this.activeGroup].length
		},
		stackedBarChartOptions () {
			return {
				indexAxis: 'y',
				plugins: {
					responsive: true,
					datalabels: {
						display: (context) => context.dataset.data[context.dataIndex] > 0,
						color: 'white',
						backgroundColor: 'transparent',
						borderColor: 'transparent',
						font: {
							weight: 'bold',
							size: 14
						}
					},
					tooltip: {
						enabled: false
					},
					legend: {
						display: true,
						position: 'bottom',
						labels: {
							font: {
								size: 14
							}
						}
					},
					title: {
						position: 'bottom',
						display: true,
						text: 'Rule Failure Count',
						font: {
							size: 18
						}
					},
				},
				responsive: true,
				scales: {
					x: {
						stacked: true,
						beginAtZero: true,
						ticks: {
							maxTicksLimit: 50,
						},
					},
					y: {
						stacked: true,
					}
				}
			}
		},
		stackedBarChartData() {
			if (!this.response && !this.response[this.activeGroup]) return

			return {
				labels: Object.values(this.response[this.activeGroup]).map((item) => item.name),
				datasets: [
					{
						label: 'Complaint',
						borderColor: '#49A22F',
						backgroundColor: '#49A22F',
						data: Object.values(this.response[this.activeGroup]).map((item) => item[`compliant${this.activeTab}`]),
						minBarLength: 2,
					},
					{
						label: 'Non-complaint',
						borderColor: '#E55627',
						backgroundColor: '#E55627',
						data: Object.values(this.response[this.activeGroup]).map((item) => item[`noCompliant${this.activeTab}`])
					},
				]
			}
		},
	},

}
</script>

<style lang="scss" scoped>
::v-deep.element-container__title-right {
	height: 40px;
}

::v-deep.chart {
	width: 100%;
}

select{
	padding: 0 5px;
	outline: 1px color(gray-400) solid;
	border-radius: 3px;
	color: color(gray-700);

	&:focus{
		outline: none;
	}
}

.component {
	max-width: 1280px;
	margin: 30px auto 0;
}

</style>
